<p-tabView [(activeIndex)]="activeIndex" class="pb-2">
  <p-tabPanel header="All" leftIcon="pi pi-history">
    @if (activeIndex === 0) {
      <app-history-panel
        [refresh]="refreshToggle"
        [itemCode]="itemCode"
        [externalSourceCode]="externalSourceCode"
      ></app-history-panel>
    }
  </p-tabPanel>
  @if (showAuditButton) {
    <p-tabPanel header="History" leftIcon="pi pi-list">
      @if (activeIndex === 1) {
        <app-audit-trail [itemCode]="itemCode"></app-audit-trail>
      }
    </p-tabPanel>
  }
  @if (showCommentsButton) {
    <p-tabPanel header="Comments" leftIcon="pi pi-comments">
      @if (activeIndex === 2) {
        <app-comments (onAddItem)="onItemsChanged($event)" [itemCode]="externalSourceCode"></app-comments>
      }
    </p-tabPanel>
  }
  <p-tabPanel header="Status Audit" leftIcon="fa-solid fa-sitemap">
    @if (activeIndex === 3) {
      <app-audit-trail [itemCode]="itemCode" [eventsFilters]="eventsFilters"></app-audit-trail>
    }
  </p-tabPanel>
  <p-tabPanel header="Attachments" leftIcon="pi pi-link">
    @if (activeIndex === 4) {
      <app-attachments-trail
        (onAddItem)="onItemsChanged($event)"
        [itemCode]="externalSourceCode"
      ></app-attachments-trail>
    }
  </p-tabPanel>
  <p-tabPanel header="Languages" leftIcon="pi pi-language">
    @if (activeIndex === 5) {
      <app-languages-trail (onAddItem)="onItemsChanged($event)" [itemCode]="externalSourceCode"></app-languages-trail>
    }
  </p-tabPanel>
</p-tabView>
