import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  ExportPackageDto,
  IExportData,
  ModuleKeywords,
  getOptionsFromStringArray,
  markFormGroupTouched,
} from '@shared/classes';
import { PathResolverService, ToastService } from '@shared/services';
import { ViewModeService } from '@shared/services/view-mode.service';
import { GenericInfoDataService } from 'app/modules/data-structure-module/services/data/generic-info-data.service';
import { intersection } from 'lodash-es';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-export-package-form',
  templateUrl: './export-package-form.component.html',
  styleUrls: ['./export-package-form.component.scss'],
})
export class ExportPackageFormComponent extends BaseForm<IExportData> implements OnInit {
  isLoadingTypes = true;
  @Input() moduleKeyword: string = null;

  private _totalElements = 10;
  get totalElements() {
    return this._totalElements;
  }
  @Input() set totalElements(totalElements: number) {
    this._totalElements = totalElements;
    this.formGroup.patchValue({ size: Math.min(totalElements, 10) });
    this.getPageList();
  }
  pageList: { label: string; value: number }[] = [];
  listOfTypesToInclude = []; // getEnumOptions(ExportPackageDto.TypesToIncludeEnum);
  getData() {
    return this.formGroup.getRawValue();
  }

  setData(data: IExportData) {
    // if (!data) return;
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      page: new FormControl(0),
      size: new FormControl(10),
      type: new FormControl('pdf'),
      typesToInclude: new FormControl([], [Validators.required, Validators.minLength(1)]),
      mode: new FormControl('EXPORT'),
      name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      description: new FormControl(null),
      exportManualRelations: new FormControl(false),
      exportReferenceRelations: new FormControl(false),
    });
    this.submitButtonAction = {
      id: 1,
      label: 'Export',
      buttonType: 'button',
      command: this.onSubmitForm.bind(this),
      icon: 'pi pi-file-export',
      buttonClass: 'w-full',
      status$: this.formValid$,
    };
  }

  constructor(
    public viewModeService: ViewModeService,
    private pathResolverService: PathResolverService,
    private genericInfoService: GenericInfoDataService,
    private toastService: ToastService
  ) {
    super(viewModeService, ModuleKeywords.ExportPackage);
  }

  ngOnInit(): void {
    this.formGroup.controls.size.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      this.getPageList(changes);
    });
    setTimeout(() => {
      // const relatedModules = this.pathResolverService.getCurrentPathRelatedModules();
      // this.listOfTypesToInclude = getOptionsFromStringArray(this.formatRelatedModules(relatedModules.relatedModules));
    }, 50);
    this.isLoadingTypes = true;
    this.genericInfoService
      .getTypes({ showLoading: false, showMsg: false, extras: { cacheKey: 'generic-info-types' } })
      .subscribe((types) => {
        const currentType = types?.data?.find((type) => type.name === this.moduleKeyword);

        this.listOfTypesToInclude = getOptionsFromStringArray(
          types?.data
            ?.filter((type) => intersection(type.modules as string[], [currentType?.modules?.[0], 'ALL']).length > 0)
            .map((type) => type.name)
        );
        this.isLoadingTypes = false;
      });
  }
  formatRelatedModules(relatedModules: ModuleKeywords[]) {
    let ret: ExportPackageDto.TypesToIncludeEnum[] = [];
    ret = relatedModules.map<any>((x) => (x == 'CONTROL_OBJECTIVE' ? 'CONTROL_OBJECTIVES' : x));
    return ret;
  }
  getPageList(step = this.formGroup.value.size) {
    if (!step) return;
    this.pageList = [];
    if (this.totalElements == 1) this.pageList.push({ label: '1 - 1', value: 0 });
    for (let i = 1, j = 1; i < this.totalElements; i += step, j++) {
      this.pageList.push({ label: `${i} - ${Math.min(this.totalElements, j * step)}`, value: j - 1 });
    }
  }
  onSubmitForm() {
    if (this.formGroup?.valid) {
      this.formSubmit.emit(this.getData());
    } else {
      markFormGroupTouched(this.formGroup);
      // const toastService = AppInjector.get(ToastService)
      this.toastService.warn('Validation Error', 'Please Fill Form Correctly');
    }
    // this.formSubmit.emit(this.getData());
  }
}
