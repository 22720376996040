@if (!showLocked) {
  <div class="w-full">
    @if (data && (this.pageViewMode == 'edit' || this.pageViewMode == 'view') && showCountDown) {
      <div class="flex gap-2 align-self-center w-full mb-2">
        <app-count-down-view
          [badgeView]="true"
          [badgeText]="'Locked For: '"
          [date]="data?.lockedUntil"
          style="width: 100%"
          (onCountDownEnd)="onCountDownEnd($event)"
          (onCountDownConditionalTimeMet)="onCountDownConditionalTimeMet($event)"
        >
          <ng-container badge-text>
            <div class="w-full flex flex-column md:flex-row justify-content-between">
              <div class="flex md:flex-row flex-column md:gap-3 gap-2">
                <div class="flex flex-row gap-1 align-items-center font-medium white-space-nowrap text-900">
                  {{ 'general.cards.auditBar.createdBy' | translate }}
                  <app-user-list-view [data]="data?.creatorName" style="scale: 0.8"></app-user-list-view>
                  @if (data?.creationDate) {
                    <div
                      [pTooltip]="data?.creationDate | date: 'yyyy-MM-dd HH:mm:ss'"
                      [tooltipPosition]="'top'"
                      class="flex flex-row gap-1 align-items-end justify-content-end"
                    >
                      <span
                        class="text-500"
                        timeago
                        [date]="data?.creationDate | date: 'yyyy-MM-dd HH:mm:ss'"
                        style="font-variant-numeric: lining-nums"
                      ></span>
                      <i class="text-500 text-sm pi pi-clock"></i>
                    </div>
                  }
                </div>
                <div class="flex flex-row gap-1 align-items-center font-medium white-space-nowrap text-900">
                  {{ 'general.cards.auditBar.lastModifiedBy' | translate }}
                  <app-user-list-view [data]="data?.lastModifierName" style="scale: 0.8"></app-user-list-view>
                  @if (data?.lastModificationDate) {
                    <div
                      [pTooltip]="data?.lastModificationDate | date: 'yyyy-MM-dd HH:mm:ss'"
                      [tooltipPosition]="'top'"
                      class="flex flex-row gap-1 align-items-end justify-content-end"
                    >
                      <span
                        class="text-500"
                        timeago
                        [date]="data?.lastModificationDate | date: 'yyyy-MM-dd HH:mm:ss'"
                        style="font-variant-numeric: lining-nums"
                      ></span>
                      <i class="text-500 text-sm pi pi-clock"></i>
                    </div>
                  }
                </div>
              </div>
              @if (data?.lockedUntil && this.pageViewMode == 'edit' && showCountDown) {
                <div class="align-self-center">
                  <span class="flex flex-row gap-1 align-items-center font-medium white-space-nowrap text-900">
                    {{ 'general.cards.auditBar.lockedBy' | translate }}
                    <app-user-list-view [data]="data?.lockedBy" style="scale: 0.8"></app-user-list-view>
                    {{ 'general.cards.auditBar.for' | translate }}
                  </span>
                </div>
              }
            </div>
          </ng-container>
        </app-count-down-view>
        <!-- <p class="mb-0">Locked Until : </p>
        <app-count-down-view [date]="data?.lockedUntil"></app-count-down-view> -->
        <!-- <app-date-view [data]="data?.lockedUntil"></app-date-view> -->
      </div>
    }
    <ng-content select="[above-main-card]"></ng-content>
    <div [class]="cardContainer ? 'card' : ''">
      <div class="pb-2 w-full flex flex-column gap-2">
        <div
          class="flex gap-2 md:justify-content-between flex-column md:flex-row"
          [class.flex-row-reverse]="pageActions.length > 0"
          [class.mb-2]="pageActions.length > 0"
        >
          @if (pageActions.length > 0) {
            <app-button-list
              class="align-self-center"
              [actionList]="pageActions"
              [commandData]="pageActionsCommandData"
            ></app-button-list>
          }
          <div class="flex flex-wrap gap-3">
            <ng-content select="[page-header]"></ng-content>
            @if (data?.recordStatus && showRecordStatus) {
              <app-drop-down-badge
                class="align-self-center"
                [badgeStatus]="data?.recordStatus"
                [dropDownActions]="recordStatusActions"
                [translationKeyPrefix]="moduleFieldString ? moduleFieldString + '.recordStatus.enums' : null"
              ></app-drop-down-badge>
            }
          </div>
          <div class="btns-wrapper flex gap-2 justify-content-end flex-wrap-reverse">
            <ng-content select="[page-actions]"></ng-content>
            @if (showViewSwitch && pageViewMode == 'edit') {
              <app-button [action]="showManageLanguageAction"></app-button>
            }
            @if (showShareButton && showViewSwitch) {
              <app-button [action]="showShareAction"></app-button>
            }
            @if (showHistoryButton) {
              <app-button [action]="showHistoryAction"></app-button>
            }
            @if (showAddCommentButton) {
              <app-button [action]="showCommentsAction"></app-button>
            }
            @if (showAddCommentButton) {
              <app-button [action]="showAttachmentsAction"></app-button>
            }
            @if (showViewSwitch) {
              <app-skeleton-loader-placeholder [isLoading]="!dataLoaded && !withoutViewLoading" [staticWidth]="'4rem'">
                <p-selectButton
                  [options]="viewModeOptions"
                  [(ngModel)]="pageViewMode"
                  optionLabel="label"
                  optionValue="value"
                  (onChange)="onChangeViewMode($event)"
                  styleClass="view-switch-buttons"
                  [allowEmpty]="false"
                >
                  <ng-template let-item>
                    <div
                      class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                      [pTooltip]="item.tooltip"
                      tooltipPosition="top"
                      style="padding: 0.629rem 0"
                    >
                      <i [class]="item.icon"></i>
                      @if (item.label) {
                        <div>
                          {{ item.label }}
                        </div>
                      }
                    </div>
                  </ng-template>
                </p-selectButton>
              </app-skeleton-loader-placeholder>
            }
            <!-- <app-button *ngIf="showAuditButton" [action]="showAuditAction"></app-button> -->
          </div>
        </div>
        @if (moduleKeyword && requestService && !hideTagsInput) {
          @if (tagsLoading) {
            <div class="mb-4 w-full h-full text-center">
              <i class="pi pi-spin pi-spinner text-400"></i>
            </div>
          } @else {
            <app-tag-search-input
              [viewMode]="pageViewMode == 'create' || pageViewMode == 'edit' ? 'create' : 'view'"
              [name]="'tags'"
              [label]="'general.fields.tags.label' | translate: 'tags'"
              placeholder="Tags"
              [altMode]="true"
              [controls]="data ? { tagStringsControl: $any(data)?.tags, tagCodesControl: tagsControl } : null"
              (onPopupSubmit)="onTagsEditSubmit($event)"
            ></app-tag-search-input>
          }
        }
        <!-- <div class="flex justify-content-between" *ngIf="showViewSwitch">
        <div class="btns-wrapper flex">
          <p-selectButton [options]="viewModeOptions" *ngIf="showViewSwitch" [(ngModel)]="pageViewMode"
            optionLabel="label" optionValue="value" (onChange)="onChangeViewMode($event)">
            <ng-template let-item>
              <div class="flex flex-row gap-2 align-items-center">
                <i [class]="item.icon"></i>
                <div>
                  {{item.label}}
                </div>
              </div>
            </ng-template>
          </p-selectButton>
        </div>
      </div> -->
      </div>
      <ng-content></ng-content>
      <ng-content select="[page-footer]"></ng-content>
    </div>
    <ng-content select="[below-main-card]"></ng-content>
    @if (showRelations) {
      <div [class]="cardContainer ? 'card' : ''">
        <app-relations
          [sourceCode]="sourceCode"
          [sourceUUID]="$any(data)?.uuid"
          [sourceType]="fromType"
          [mode]="viewModeService.viewMode"
          [reloadData]="refreshRelations"
        >
        </app-relations>
      </div>
    }
    <!-- <app-side-bar-comments [itemCode]="externalSourceCode ?? sourceCode" [display]="showComments"
  (onCloseComments)="closeComments()"
  [autoFocus]="true"
></app-side-bar-comments> -->
    <!--
<app-side-bar-state-machine-audit [itemCode]="sourceCode" [display]="showStateMachineAuditTrail | async"
  (onCloseComments)="closeStateMachineAuditTrail()"
  [autoFocus]="true"
></app-side-bar-state-machine-audit> -->
    <app-side-bar-audit-trail
      [itemCode]="sourceCode"
      [display]="showAudit"
      (onCloseComments)="closeAudit()"
    ></app-side-bar-audit-trail>
    <app-side-bar-history
      [itemCode]="sourceCode"
      [externalSourceCode]="externalSourceCode ?? sourceCode"
      [display]="showHistory || (showStateMachineAuditTrail | async)"
      [showCommentsButton]="showCommentsButton"
      [showAuditButton]="showAuditButton"
      (onCloseSidebar)="closeHistory()"
      [activeIndex]="activeIndex"
    ></app-side-bar-history>
  </div>
}
@if (showLocked) {
  <app-locked-item></app-locked-item>
}
<p-sidebar
  #extendTimeDialog
  [(visible)]="visibleSidebar5"
  [fullScreen]="true"
  [showCloseIcon]="false"
  [baseZIndex]="99999999"
>
  <div class="p-dialog-content">
    <div class="flex flex-column align-items-center my-4">
      <span
        class="flex align-items-center justify-content-center bg-red-100 text-red-800 mr-3 border-circle mb-3"
        style="width: 64px; height: 64px"
        ><i class="pi pi-stopwatch text-5xl"></i
      ></span>
      <div class="font-medium text-2xl text-900">Lock Time Almost Expired</div>
      <p class="line-height-3 p-0 m-0">
        To continue editing and be able to save your changes please extend the lock time before it ends.
      </p>
    </div>
  </div>
  <div class="p-dialog-footer">
    <div
      class="border-top-1 surface-border pt-3 flex justify-content-center align-content-center gap-3 white-space-nowrap"
    >
      @if (this.pageViewMode == 'edit' && extendLockTimeAction[0]) {
        <app-button
          [action]="extendLockTimeAction[0]"
          [commandData]="{ row: data, extendTimeDialog: extendTimeDialog }"
          [displayData]="data"
        >
          @if (data?.lockedUntil) {
            <app-count-down-view
              [badgeView]="false"
              [badgeText]="'Locked For: '"
              [date]="data?.lockedUntil"
              class="text-red-500"
              style="width: 100%"
            >
              <ng-container badge-text>
                <span class="font-medium white-space-nowrap">
                  Locked By <strong> {{ data?.lockedBy }} </strong>
                  For:
                </span>
              </ng-container>
            </app-count-down-view>
          }
        </app-button>
      }
      @if (this.pageViewMode == 'edit' && extendLockTimeAction[1]) {
        <app-button
          [action]="extendLockTimeAction[1]"
          [commandData]="{ row: data, extendTimeDialog: extendTimeDialog }"
          [displayData]="data"
        ></app-button>
      }
      <!-- <app-button-list *ngIf="this.pageViewMode == 'edit'" class="w-fit" [actionList]="extendLockTimeAction"
  [listType]="'list'" [commandData]="{row:data,extendTimeDialog:extendTimeDialog}" [displayData]="data"></app-button-list> -->
    </div>
  </div>
</p-sidebar>
