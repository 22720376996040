import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, Tag } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-tag-item-form',
  templateUrl: './tag-item-form.component.html',
  styleUrl: './tag-item-form.component.scss',
})
export class TagItemFormComponent extends BaseForm<Tag> implements OnInit {
  contextVariables: any[] = [];

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'TAG');
  }

  ngOnInit(): void {}

  getData() {
    if (this.popupOptions?.fromPopup) return this.getDataKeyValueFormat();
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
