import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import {
  AccessRecord,
  BaseForm,
  DataTypeEnum,
  IAction,
  IColumn,
  OfflineConfig,
  PermissionActions,
  getEnumOptions,
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { cloneDeep } from 'lodash-es';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-item-share-tray',
  templateUrl: './item-share-tray.component.html',
  styleUrls: ['./item-share-tray.component.scss'],
})
export class ItemShareTrayComponent extends BaseForm<any> implements OnInit {
  groupedSubscribers: { groupTitle: string; type: string; icon: string; items: any[] }[] = [];
  selectedItems: string[] = [];
  private _subscribers: any[] = [];
  public get subscribers(): any[] {
    return this._subscribers;
  }
  public set subscribers(value: any[]) {
    this._subscribers = value;
    this.groupedSubscribers = [];
    this.selectedItems = value?.map((x) => x);
    const respList = value.filter((x) => x.principleType == 'RESPONSIBILITY');
    const groupList = value.filter((x) => x.principleType == 'GROUP');
    const userList = value.filter((x) => x.principleType == 'USER');
    const nullList = value.filter(
      (x) =>
        !x.principleType ||
        (x.principleType != 'RESPONSIBILITY' && x.principleType != 'GROUP' && x.principleType != 'USER')
    );
    const tempList = [];
    respList?.length > 0
      ? tempList.push({ groupTitle: '', type: 'Responsibilities', icon: 'pi pi-user-plus', items: respList })
      : undefined;
    groupList?.length > 0
      ? tempList.push({ groupTitle: '', type: 'Groups', icon: 'pi pi-users', items: groupList })
      : undefined;
    userList?.length > 0
      ? tempList.push({ groupTitle: '', type: 'USERS', icon: 'pi pi-user', items: userList })
      : undefined;
    nullList?.length > 0
      ? tempList.push({ groupTitle: '', type: 'Others', icon: 'pi pi-question', items: nullList })
      : undefined;
    this.groupedSubscribers = [...tempList];
  }
  userList: string[] = [];
  loading: boolean = true;
  isSubscribed: boolean = false;
  subscribeAction: IAction = {
    id: 1,
    label: 'Done',
    buttonType: 'button',
    command: this.onAddSubscribers.bind(this),
    color: 'primary',
    buttonClass: 'w-fit',
  };
  baseAccessAction: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.subscribe.bind(this),
    color: 'secondary',
    buttonClass: 'w-fit',
  };
  fullActionList: IAction[] = [];
  private _itemId: string;
  @Input() set itemId(itemId: string) {
    this.subscribeAction = itemId
      ? {
          ...this.subscribeAction,
          permission: `${PermissionActions.Delete}${this.pathResolver?.getModuleKeywordByCode(itemId)}`,
        }
      : null;
    this._itemId = itemId;
  }
  get itemId() {
    return this._itemId;
  }
  formControl = new FormControl();
  accessLevels = getEnumOptions(AccessRecord.AccessLevelEnum);
  constructor(
    private toastService: ToastService,
    private jwtTokenService: JWTTokenService,
    private route: ActivatedRoute,
    private pathResolver: PathResolverService,
    private appDialogService: AppDialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, null);
    this.itemId = this.config?.data?.itemId ?? this.itemId;
    this.fullActionList = Object.keys(AccessRecord.AccessLevelEnum).map((level, idx) => {
      return {
        ...this.baseAccessAction,
        icon: null,
        id: idx + 2,
        label: level,
        command: this.changeCurrentUserAccessLevel.bind(this, AccessRecord.AccessLevelEnum[level]),
      };
    });
  }

  ngOnInit(): void {
    this.setCols();
    // this.setTableActions();
    this.getSubscribers();
  }
  getSubscribers() {
    if (!this.itemId) return;
    this.loading = true;
    this.pathResolver
      ?.getDataServiceByCode(this.itemId)
      .getAccessList(this.itemId)
      .subscribe({
        next: (res) => {
          this.subscribers = res.data.map((item) => {
            return {
              ...item,
              actionList: [
                ...Object.keys(AccessRecord.AccessLevelEnum).map((level, idx) => {
                  return {
                    ...this.baseAccessAction,
                    icon: AccessRecord.AccessLevelEnum[level] == item.accessLevel ? 'pi pi-check' : null,
                    id: idx + 2,
                    label: level,
                    command:
                      AccessRecord.AccessLevelEnum[level] == item.accessLevel
                        ? () => {}
                        : this.subscribe.bind(this, [
                            {
                              principle: item.principle,
                              principleType: item?.principleType,
                              accessLevel: AccessRecord.AccessLevelEnum[level],
                            },
                          ]),
                  };
                }),
                {
                  id: 99,
                  separator: true,
                },
                {
                  ...this.baseAccessAction,
                  label: 'Remove Access',
                  command: this.unsubscribe.bind(this, item.principle),
                },
              ],
            };
          });
          this.onSetSubscribers();
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }
  subscribe(body: AccessRecord[]) {
    this.loading = true;
    this.pathResolver
      ?.getDataServiceByCode(this.itemId)
      .grantAccess(body, this.itemId)
      .subscribe((res) => {
        this.formControl.patchValue(null);
        setTimeout(() => {
          this.getSubscribers();
        }, 50);
      });
  }
  unsubscribe(principle: string) {
    this.loading = true;
    this.pathResolver
      ?.getDataServiceByCode(this.itemId)
      .removeAccess(this.itemId, principle)
      .subscribe((res) => {
        // this.toastService.success("Unsubscribed from this feed",'',null,primengToastPlacement['top-left']);
        setTimeout(() => {
          this.getSubscribers();
        }, 50);
      });
  }
  onSetSubscribers() {
    this.userList = [];
    this.isSubscribed = false;
    this.subscribers.forEach((sub) => {
      this.userList.push(sub.principle);
      if (sub.principle == this.jwtTokenService.getPreferredUsername()) {
        this.isSubscribed = true;
      }
    });
  }
  getData() {
    return this.formGroup.getRawValue();
  }
  setData(data: any) {
    this.formGroup.patchValue(data);
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      principle: new FormControl(null),
      accessLevel: new FormControl('READ'),
    });
  }
  onAddSubscribers() {
    if (this.formControl?.value?.length > 0) {
      const body = Object.keys(this.userActionsMap).map((key) => {
        const item = this.userActionsMap[key];
        return {
          principle: key,
          accessLevel: item.accessLevel,
          principleType: item.principleType,
          accessType: AccessRecord.AccessTypeEnum.Manual,
        };
      });
      this.subscribe(body);
    } else {
      this.ref.close();
    }
  }
  currentUserAccessLevel = 'READ';
  changeCurrentUserAccessLevel(level) {
    this.currentUserAccessLevel = level;
  }

  changeUserAccessLevel(user, level) {
    this.userActionsMap[user].accessLevel = level;
  }
  userActionsMap: {
    [x: string]: { actions: any[]; accessLevel: AccessRecord.AccessLevelEnum; principleType: 'GROUP' | 'USER' };
  } = {};
  removeDuplicates(objects: any[]): any[] {
    const seen = new Set<string>();
    const uniqueObjects: any[] = [];

    for (const obj of objects) {
      const serialized = JSON.stringify(obj);
      if (!seen.has(serialized)) {
        seen.add(serialized);
        uniqueObjects.push(obj);
      }
    }

    return uniqueObjects;
  }
  setUserListActions(dataList) {
    const newDataList = this.removeDuplicates(dataList);

    let tempList: {
      [x: string]: { actions: any[]; accessLevel: AccessRecord.AccessLevelEnum; principleType: 'GROUP' | 'USER' };
    } = {};
    newDataList.forEach((user) => {
      // if(!this?.subscribers?.find(x => x?.name === user)){
      tempList[user.name] = this.userActionsMap[user.name] || {
        actions: this.getActionList(user.name),
        accessLevel: 'READ',
        principleType: user.type,
      };
      // }
    });
    if (newDataList?.length != dataList?.length) {
      this.formControl.patchValue(newDataList);
    }
    this.userActionsMap = cloneDeep(tempList);
  }
  getActionList(principle: string) {
    return Object.keys(AccessRecord.AccessLevelEnum).map((level, idx) => {
      return {
        ...this.baseAccessAction,
        icon: null,
        id: idx + 2,
        label: level,
        command: this.changeUserAccessLevel.bind(this, principle, AccessRecord.AccessLevelEnum[level]),
      };
    });
  }
  setAdmin(row) {
    this.changeUserAccessLevel.bind(this, row.principle, AccessRecord.AccessLevelEnum.Admin);
  }
  setRead(row) {
    this.changeUserAccessLevel.bind(this, row.principle, AccessRecord.AccessLevelEnum.Read);
  }
  setOwner(row) {
    this.changeUserAccessLevel.bind(this, row.principle, AccessRecord.AccessLevelEnum.Owner);
  }
  setUpdate(row) {
    this.changeUserAccessLevel.bind(this, row.principle, AccessRecord.AccessLevelEnum.Update);
  }
  get excludedItems() {
    return [...this.selectedItems];
  }
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };
  tableActions: IAction[] = [
    {
      id: 99,
      separator: true,
      group: { id: 1, type: 'split' },
      displayCommand: (row) => false,
    },
  ];
  setTableActions() {
    // this.tableActions = [
    //     {
    //         id: 1,
    //         icon: 'pi pi-list',
    //         color: ButtonColor.Secondary,
    //         command: this.setAdmin.bind(this),
    //         tooltipText: 'Admin'
    //     },
    //     {
    //         ...this.baseAccessAction,
    //         icon: AccessRecord.AccessLevelEnum[level] == item.accessLevel ? "pi pi-check" : null,
    //         id: 2,
    //         label: 'Admin',
    //         command: AccessRecord.AccessLevelEnum[level] == item.accessLevel ?
    //             () => { } : this.subscribe.bind(this, [{ principle: item.principle, principleType: item?.principleType, accessLevel: AccessRecord.AccessLevelEnum.Admin }]),
    //     },
    //     {
    //         id: 99,
    //         separator: true
    //     }, { ...this.baseAccessAction, label: 'Remove Access', command: this.unsubscribe.bind(this) }
    // ]
    const staticActions = [];
    Object.keys(AccessRecord.AccessLevelEnum).forEach((level, idx) => {
      const defaultAction: IAction = {
        ...this.baseAccessAction,
        icon: null,
        id: idx + 2,
        label: level,
        command: (item) => {
          this.subscribe([
            {
              principle: item.principle,
              principleType: item?.principleType,
              accessLevel: AccessRecord.AccessLevelEnum[level],
            },
          ]);
        },
        displayCommand: (row) => AccessRecord.AccessLevelEnum[level] != row.accessLevel,
        group: { id: 1, type: 'split' },
      };
      const checkedAction: IAction = {
        ...this.baseAccessAction,
        icon: 'pi pi-check',
        id: idx + 22,
        label: level,
        command: () => {},
        displayCommand: (row) => AccessRecord.AccessLevelEnum[level] == row.accessLevel,
        group: { id: 1, type: 'split' },
      };
      staticActions.push(defaultAction);
      staticActions.push(checkedAction);
    });
    const checkedActions = [];
    Object.keys(AccessRecord.AccessLevelEnum).forEach((level, idx) => {
      const checkedAction: IAction = {
        ...this.baseAccessAction,
        icon: null,
        id: idx + 22,
        label: level,
        command: () => {},
        displayCommand: (row) => AccessRecord.AccessLevelEnum[level] == row.accessLevel,
        group: { id: 1, type: 'split' },
      };
      checkedActions.push(checkedAction);
    });
    this.tableActions = [
      ...checkedActions,
      ...staticActions,
      {
        id: 99,
        separator: true,
        group: { id: 1, type: 'split' },
      },
      {
        ...this.baseAccessAction,
        label: 'Remove Access',
        command: (item) => {
          this.unsubscribe(item.principle);
        },
        group: { id: 1, type: 'split' },
      },
    ];
  }
  setCols() {
    this.cols = [
      {
        name: 'Principle',
        key: 'principle',
        dataType: DataTypeEnum.UserListView,
        // filter: {
        //     type: "text",
        //     matchMode: "startsWith",
        // },
        // frozen: true,
        // alignFrozen: "left",
        sortDisabled: true,
      },
      {
        name: 'Reference Name',
        key: 'referenceName',
        dataType: DataTypeEnum.Text,
        // filter: {
        //     type: "text",
        //     display: "menu",
        //     matchMode: "startsWith",
        //     showMatchModes: true,
        //     showAddButton: true,
        //     showOperator: true,
        // },
        sortDisabled: true,
      },
      {
        name: 'Reference Value',
        key: 'referenceValue',
        dataType: DataTypeEnum.CodeLink,
        // filter: {
        //     type: "text",
        //     display: "menu",
        //     matchMode: "startsWith",
        //     showMatchModes: true,
        //     showAddButton: true,
        //     showOperator: true,
        // },
        sortDisabled: true,
      },
      {
        name: 'Access Type',
        key: 'accessType',
        dataType: DataTypeEnum.Badge,
        // filter: {
        //     type: "text",
        //     display: "menu",
        //     matchMode: "startsWith",
        //     showMatchModes: true,
        //     showAddButton: true,
        //     showOperator: true,
        // },
        sortDisabled: true,
      },
      // {
      //     name: "Enabled",
      //     key: "enable",
      //     dataType: DataTypeEnum.Text,
      //     // description: "Is this user enabled?",
      // },
      // {
      //     name: "System Admin",
      //     key: "sfSysAdmin",
      //     dataType: DataTypeEnum.Badge,
      //     // description: "Is this user enabled?",
      //     filter: {
      //         type: "boolean",
      //         display: "menu",
      //         matchMode: "equals",
      //     },
      // },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        sortDisabled: true,
      },
      {
        name: 'Creation Date',
        key: 'creationDate',
        dataType: DataTypeEnum.DateLong,
        sortDisabled: true,
      },
      // {
      //     name: "Last Modifier",
      //     key: "lastModifierName",
      //     dataType: DataTypeEnum.UserListView,
      //     filter: {
      //         type: "multiDropdown",
      //         matchMode: 'in',
      //         showMatchModes: false,
      //         showAddButton: false,
      //         showOperator: false,
      //         dynamicInput: {
      //             componentType: UserSelectorComponent,
      //             options: {
      //                 label: '',
      //                 name: '',
      //                 control: new FormControl(null),
      //             }
      //         }
      //     },
      // },
    ];
  }
}
