<p-panel
  [toggleable]="true"
  [collapsed]="checkCollapsedType(data.type)"
  [expandIcon]="'pi pi-angle-up'"
  [collapseIcon]="'pi pi-angle-down'"
>
  <ng-template pTemplate="header">
    <app-user-list-view
      [data]="data.createdUser ? [data.createdUser] : (data.creatorName ?? ['No User'])"
    ></app-user-list-view>
    <!-- <span class="ml-2 font-medium text-base">{{ data.createdUser ?? data.creatorName }}</span> -->
    <span class="ml-2 font-normal text-base">{{ data.actionTitle ?? 'added a Comment' }}</span>
    @if (data.version != undefined) {
      <app-badge-item
        [styleClass]="'ml-2 min-w-0'"
        [customColor]="'#64a2ff'"
        [text]="'V' + data.version"
      ></app-badge-item>
    }
  </ng-template>
  <div>
    @for (field of data.extraData; track field; let isLast = $last) {
      <div class="field-row">
        @if (!checkHiddenLable(field.fieldName)) {
          <div class="p-card-subtitle field-name">{{ field.fieldName | humanize }}</div>
        }
        <div class="field-value-row">
          <div class="filled-circle-row">
            @if (!checkType(mappedFields?.[field?.fieldName]?.dataType)) {
              <div
                class="filled-circle"
                pTooltip="New Value"
                tooltipPosition="top"
                [ngClass]="{ hidden: field.newValue === null }"
              ></div>
            }
            <div
              class="filled-value w-full"
              [ngClass]="{ hidden: field.newValue === null }"
              [ngStyle]="{ 'margin-left': checkType(mappedFields?.[field?.fieldName]?.dataType) ? '0px' : '5px' }"
            >
              <app-view-switch
                [viewType]="
                  mappedFields?.[field?.fieldName]?.tableDefinition?.passRowAsData
                    ? dataTypes.Text
                    : mappedFields?.[field?.fieldName]?.dataType || dataTypes.Text
                "
                [data]="
                  checkType(
                    mappedFields?.[field?.fieldName]?.tableDefinition?.passRowAsData
                      ? dataTypes.Text
                      : mappedFields?.[field?.fieldName]?.dataType || dataTypes.Text
                  )
                    ? field
                    : field.newValue
                "
                [options]="mappedFields?.[field?.fieldName]?.tableDefinition?.options"
              >
              </app-view-switch>
            </div>
          </div>
        </div>
        @if (!checkType(mappedFields?.[field?.fieldName]?.dataType)) {
          <div class="empty-circle-row">
            <div
              class="empty-circle"
              pTooltip="Old Value"
              tooltipPosition="top"
              [ngClass]="{ hidden: field.oldValue === null }"
            ></div>
            <div class="empty-value" [ngClass]="{ hidden: field.oldValue === null }">
              <app-view-switch
                [viewType]="
                  mappedFields?.[field?.fieldName]?.tableDefinition?.passRowAsData
                    ? dataTypes.Text
                    : mappedFields?.[field?.fieldName]?.dataType || dataTypes.Text
                "
                [data]="
                  checkType(
                    mappedFields?.[field?.fieldName]?.tableDefinition?.passRowAsData
                      ? dataTypes.Text
                      : mappedFields?.[field?.fieldName]?.dataType || dataTypes.Text
                  )
                    ? field
                    : field.oldValue
                "
                [options]="mappedFields?.[field?.fieldName]?.tableDefinition?.options"
              >
              </app-view-switch>
            </div>
          </div>
        }
        <div class="field-value-row" [ngClass]="{ hidden: isLast }"></div>
      </div>
    }
  </div>
</p-panel>
<div class="p-panel">
  <div class="p-panel-footer">
    <div class="flex flex-wrap align-items-center justify-content-between gap-3">
      @if (!hideCode) {
        <div class="flex align-items-center gap-2">
          <app-code-navigation [data]="data?.targetCode"></app-code-navigation>
        </div>
      }
      <div
        class="p-card-subtitle mt-1 ml-2 flex align-items-center"
        timeago
        [date]="data.creationDate | date: 'yyyy-MM-dd HH:mm:ss'"
      ></div>
    </div>
  </div>
</div>
