import { Component, Input, OnInit } from '@angular/core';
import { AppEnumIcons, BadgeColor, Base, DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { AuditTrailService } from '@shared/services/audit-trail-service/relations.service';
import { CommentsService } from '@shared/services/comments-service/relations.service';
import { orderBy } from 'lodash-es';
import { PrimeIcons } from 'primeng/api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-history-panel',
  templateUrl: './history-panel.component.html',
  styleUrls: ['./history-panel.component.scss'],
})
export class HistoryPanelComponent extends Base implements OnInit {
  events: any[];
  color = '#9C27B0';
  commentColor = '#2ebae4';
  loading = true;
  iconMap = AppEnumIcons;
  colorMap = BadgeColor;
  icon = PrimeIcons.BOOKMARK;
  commentIcon = PrimeIcons.COMMENT;

  @Input()
  itemCode: string;
  @Input()
  externalSourceCode: string;
  @Input() set refresh(refresh) {
    if (refresh) this.loadData();
  }
  textDataType = DataTypeEnum.Text;

  mappedFields: {
    [key: string]: IFieldDefinition;
  };
  constructor(
    private auditTrailService: AuditTrailService,
    private commentsService: CommentsService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }

  ngOnInit() {
    this.mappedFields = this.pathResolverService.getMappingServiceByModuleKeyword(
      this.pathResolverService.getModuleKeywordByCode(this.itemCode)
    ).mappedFields;
    this.loadData();
  }
  checkType(dataType: DataTypeEnum) {
    return this.auditTrailService.checkType(dataType);
  }

  hideLable(dataType: DataTypeEnum) {}
  private loadData() {
    this.loading = true;
    forkJoin({
      audits: this.auditTrailService.search(
        { all: true, sort: ['creationDate,desc'] },
        {
          filters: [{ property: 'targetCode', operation: 'EQUAL', value: this.itemCode }],
        }
      ),
      comments: this.commentsService.getBySourceCode(this.externalSourceCode),
    }).subscribe({
      next: ({ audits, comments }) => {
        //audits.data = this.auditTrailService.checkSpecialFields(audits, this.mappedFields);

        this.events = (audits as any as any[]).concat(comments.data);
        this.events = orderBy(this.events, ['creationDate'], ['desc']);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
