@if (loading) {
  <div class="flex justify-content-center align-items-center w-full">
    <!-- <p-progressSpinner></p-progressSpinner> -->
    <ul class="m-0 p-0 list-none w-full">
      <li class="mb-3">
        <div class="flex w-full">
          <p-skeleton shape="circle" size="2rem" styleClass="mr-3"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" height="7rem"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
}

<div class="flex flex-row flex-wrap gap-1 mt-3">
  @for (langKey of languageKeys; track langKey; let i = $index) {
    <div class="w-full flex flex-row justify-content-between p-4">
      <span
        >{{ langKey.key }} ({{ 'general.messages.translatedFields' | translate }}: {{ langKey.valueFieldsCount }})</span
      >
      <div class="flex flex-row gap-3">
        <app-button [action]="swapItemAction" [commandData]="langKey.key"></app-button>
        <app-button [action]="editItemAction" [commandData]="langKey.key"></app-button>
      </div>
    </div>
  }
  <app-button class="w-12" [action]="addItemAction"></app-button>
</div>

@if (!loading && !!events && events?.length == 0) {
  <div class="flex justify-content-center align-items-center w-full">
    <span class="font-medium text-base text-center w-full"> Add Translation <i class="pi pi-link"></i> </span>
  </div>
}
