import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeColor, Base } from '@shared/classes';
import { CommentsService } from '@shared/services/comments-service/relations.service';
import { PrimeIcons } from 'primeng/api';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends Base implements OnInit {
  events: any[];
  color = '#2ebae4';
  loading = true;
  colorMap = BadgeColor;
  icon = PrimeIcons.COMMENT;
  @Output() onAddItem: EventEmitter<any> = new EventEmitter();
  @Input()
  itemCode: string;
  @Input() autoFocus: boolean = false;

  constructor(private commentsService: CommentsService) {
    super();
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.loading = true;

    this.commentsService
      .getBySourceCode(this.itemCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loading = false;
        this.events = res.data;
      });
  }

  onSubmit(data) {
    let body = {
      comment: data?.comment,
      sourceCode: this.itemCode,
    };

    this.loading = true;

    this.commentsService
      .create(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((c) => {
        this.events.unshift(c.data);
        this.onAddItem.emit(c.data);
        this.loading = false;
      });
  }
}
