import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, IAction } from '@shared/classes';
import { AppDialogService, ViewModeService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-tags-edit-popup-form',
  templateUrl: './tags-edit-popup-form.component.html',
  styleUrl: './tags-edit-popup-form.component.scss',
})
export class TagsEditPopupFormComponent extends BaseForm<any> implements OnInit {
  tagsControl = new FormControl(null);
  tagsStringControl = new FormControl(null);
  tagsObject;

  tabItems = [];
  searchItems = [];
  loading = false;

  cancelButton: IAction = {
    id: 1,
    label: 'Cancel',
    buttonType: 'button',
    command: this.onCancel.bind(this),
    icon: 'pi pi-times',
  };
  saveButton: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmit.bind(this),
    icon: 'pi pi-save',
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, 'TAG');
    if (!this.data) this.data = {};
    this.formData = this.data;
    this.tagsControl = this.config.data.tagsControl;
    this.tagsStringControl = this.config.data.tagsStringControl;
    this.tagsObject = { tagStringsControl: this.tagsStringControl, tagCodesControl: this.tagsControl };
  }
  ngOnInit(): void {}
  getData() {
    return this.data;
  }
  setData(data: any) {}
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      tags: new FormControl(null),
    });
  }

  onCancel() {
    this.ref.close(false);
  }
  onSubmit() {
    this.ref.close({ tagStrings: this.tagsStringControl.value, tagCodes: this.tagsControl.value });
  }
}
