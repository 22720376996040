<div class="formgrid grid p-fluid">
  <div class="col-12">
    <app-number-input
      [label]="'Rows'"
      [placeholder]="'Number Of Rows'"
      [name]="'page'"
      [showButtons]="true"
      [control]="formGroup?.controls?.page"
      [type]="'number'"
      [viewMode]="'create'"
      [min]="1"
    ></app-number-input>
  </div>
</div>
<div class="flex flex-row-reverse gap-2">
  <app-button [action]="saveButton"></app-button>
</div>
