<div class="align-self-center">
  <app-badge-item
    [displayText]="false"
    [status]="badgeStatus"
    [text]="translationKeyPrefix ? (translationKeyPrefix + '.' + badgeStatus | translate) : badgeStatus"
    (click)="menu.toggle($event)"
    class="cursor-pointer"
  >
    <div class="flex gap-2 align-items-center">
      <i [class]="(loaderService?.loadingState | async) ? ' pi pi-spin pi-spinner ' : (badgeStatus | getEnumIcon)"></i>
      <span>
        {{ translationKeyPrefix ? (translationKeyPrefix + '.' + badgeStatus | translate) : badgeStatus }}
      </span>
      <i class="pi pi-angle-down"></i>
    </div>
  </app-badge-item>
  <p-menu #menu [popup]="true" [model]="dropDownActions"></p-menu>
</div>
