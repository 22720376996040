import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RelationsService } from '@shared/services/relation-service/relations.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AcknowledgmentRequest, getAllowedTargetTypes } from 'app/shared/classes';
import { BaseForm } from 'app/shared/classes/view/BaseForm';
import { NgxPermissionsService } from 'ngx-permissions';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-relation-item-form',
  templateUrl: './relation-item-form.component.html',
  styleUrls: ['./relation-item-form.component.scss'],
})
export class RelationItemFormComponent extends BaseForm<any> implements OnInit {
  tagType = AcknowledgmentRequest.TargetTypeEnum.RelationType;
  listOfTypes = []; //getEnumOptions(TypesWithName);

  selectedType;
  @Input() sourceCode: string;

  constructor(
    private relationsService: RelationsService,
    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService
  ) {
    super(viewModeService, 'RELATION');
  }

  ngOnInit(): void {
    this.formGroup?.controls?.targetType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.selectedType = res ?? null;

      this.formGroup?.patchValue({
        targetCode: null,
      });
    });
    this.listOfTypes = getAllowedTargetTypes(this.permissionService);
  }

  getData() {
    return {
      ...this.formGroup.getRawValue(),
      id: this.data?.id,
    };
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      targetType: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      targetCode: new FormControl(null, Validators.required),
      targetUUID: new FormControl(null),
      relationType: new FormControl(null, Validators.required),
    });
  }
}
