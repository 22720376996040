import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-tabs',
  templateUrl: './history-tabs.component.html',
  styleUrls: ['./history-tabs.component.scss'],
})
export class HistoryTabsComponent implements OnInit {
  @Input() itemCode: string;
  @Input() activeIndex: number = 0;
  @Input() externalSourceCode: string;
  @Input() showAuditButton: boolean = true;
  @Input() showCommentsButton: boolean = true;
  eventsFilters?: any[] = ['CHANGE_STATE'];
  refreshToggle = false;
  constructor() {}

  ngOnInit(): void {}
  onItemsChanged(event) {
    this.refreshToggle = true;
    setTimeout(() => {
      this.refreshToggle = false;
    }, 1);
  }
}
