import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModuleKeywords } from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { Subject } from 'rxjs';
import { BaseRequestControllerService } from '../api/base-request-controller.service';
import { PathResolverService } from '../path-resolver.service';
import { ViewModeService } from '../view-mode.service';

@Injectable({
  providedIn: 'root',
})
export class BulkOperationService {
  editMode: boolean = false;
  itemId: any;
  operation: BulkOperation;
  items: any[];
  activeStep = 0;

  moduleKeyword: ModuleKeywords;
  routePrefix: string;
  completedCounterS = 0;
  completedCounterF = 0;
  totalCount = 0;
  operationStatuses = {};
  checkControls;
  clearState = new FormControl();
  mappingService;
  dataService: BaseRequestControllerService<any>;

  tempAccesses;
  constructor(
    private router: Router,
    private viewModeService: ViewModeService,
    private pathResolverService: PathResolverService
  ) { }
  stepperItem: any = {};

  setupStepper(items: any[]) {
    this.items = items;
  }

  setModule(moduleKeyword: ModuleKeywords, routePrefix: string, dataService: BaseRequestControllerService<any>) {
    this.moduleKeyword = moduleKeyword;
    this.clearData();
    this.dataService = dataService;
    this.routePrefix = routePrefix;
    this.mappingService = this.pathResolverService.getMappingServiceByModuleKeyword(this.moduleKeyword);
    this.checkControls = this.mappingService.recordFields?.filter(f => f.useInBulk).map((x) => new FormControl(true));
  }

  private stepperComplete = new Subject();
  stepperComplete$ = this.stepperComplete.asObservable();
  complete() {
    this.returnToList();
  }
  clearData() {
    this.mappingService?.recordFields?.filter(f => f.useInBulk).forEach((x) => {
      if (x?.formField?.options?.control) {
        x?.formField?.options?.control?.reset();
      }
    });
    this.dataService = null;
    this.tempAccesses = undefined;
    this.completedCounterS = 0;
    this.completedCounterF = 0;
    this.operationStatuses = {};
    this.stepperItem = {};
  }
  goToStep1() {
    this.activeStep = 0;
    // this.router.navigate([`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/bulk-operation/operation`])
  }
  goToStep2() {
    this.activeStep = 1;
    // this.router.navigate([`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/bulk-operation/operation-details`])
  }
  async goToStep3() {
    this.activeStep = 2;
    // await this.router.navigate([`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/bulk-operation/confirmation`])
  }
  async goToStep4() {
    this.activeStep = 3;
    // await this.router.navigate([`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/bulk-operation/results`])
    this.stepperComplete.next({ data: this.stepperItem, operation: this.operation, items: this.items });
  }
  returnToList() {
    //this.router.navigate([`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/list`]);
    this.dataService.navigateToListPage();
  }
}
