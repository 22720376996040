import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IExportData } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-export-data-form',
  templateUrl: './export-data-form.component.html',
  styleUrls: ['./export-data-form.component.scss'],
})
export class ExportDataFormComponent extends BaseForm<IExportData> implements OnInit {
  private _totalElements = 10;
  get totalElements() {
    return this._totalElements;
  }
  @Input() set totalElements(totalElements: number) {
    this._totalElements = totalElements;
    this.formGroup.patchValue({ size: Math.min(totalElements, 10) });
    this.getPageList();
  }
  pageList: { label: string; value: number }[] = [];

  getData() {
    return this.formGroup.getRawValue();
  }

  setData(data: IExportData) {
    // if (!data) return;
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      page: new FormControl(0, Validators.required),
      size: new FormControl(10, Validators.required),
      type: new FormControl('pdf', Validators.required),
    });
    this.submitButtonAction = {
      id: 1,
      label: 'Export',
      buttonType: 'button',
      command: this.onSubmitForm.bind(this),
      icon: 'pi pi-file-export',
      buttonClass: 'w-full',
      status$: this.formValid$,
    };
  }

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, null);
  }

  ngOnInit(): void {
    this.formGroup.controls.size.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      this.getPageList(changes);
    });
  }

  getPageList(step = this.formGroup.value.size) {
    if (!step) return;
    this.pageList = [];
    if (this.totalElements == 1) this.pageList.push({ label: '1 - 1', value: 0 });
    for (let i = 1, j = 1; i < this.totalElements; i += step, j++) {
      this.pageList.push({ label: `${i} - ${Math.min(this.totalElements, j * step)}`, value: j - 1 });
    }
  }
  onSubmitForm() {
    this.formSubmit.emit(this.getData());
  }
}
