import { Component, Input, OnInit } from '@angular/core';
import { AppEnumIcons, BadgeColor, Base, DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { AuditTrailService } from '@shared/services/audit-trail-service/relations.service';
import { PrimeIcons } from 'primeng/api';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss'],
})
export class AuditTrailComponent extends Base implements OnInit {
  events: any[];
  color = '#9C27B0';
  loading = true;
  iconMap = AppEnumIcons;
  colorMap = BadgeColor;
  icon = PrimeIcons.BOOKMARK;

  @Input()
  itemCode: string;

  @Input()
  eventsFilters?: any[] = [];

  mappedFields: {
    [key: string]: IFieldDefinition;
  };

  textDataType = DataTypeEnum.Text;

  constructor(
    private auditTrailService: AuditTrailService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }

  checkType(dataType: DataTypeEnum) {
    return this.auditTrailService.checkType(dataType);
  }

  ngOnInit() {
    this.mappedFields = this.pathResolverService.getMappingServiceByModuleKeyword(
      this.pathResolverService.getModuleKeywordByCode(this.itemCode)
    ).mappedFields;
    this.loadData();
  }

  private loadData() {
    this.loading = true;

    this.auditTrailService
      .search(
        { all: true, sort: ['creationDate,desc'] },
        {
          filters: [{ property: 'targetCode', operation: 'EQUAL', value: this.itemCode }],
        }
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.loading = false;

        //res.data = this.auditTrailService.checkSpecialFields(res, this.mappedFields);

        if (this.eventsFilters && this.eventsFilters.length > 0) {
          this.events = res.filter((el) => this.eventsFilters.includes(el.type));
          return;
        }

        this.events = res;
      });
  }
}
