import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-bar-history',
  templateUrl: './side-bar-history.component.html',
  styleUrls: ['./side-bar-history.component.scss'],
})
export class SideBarHistoryComponent implements OnInit {
  events: any[];

  @Input()
  display: boolean = true;

  @Input() activeIndex: number = 0;

  @Output()
  onCloseSidebar: EventEmitter<boolean> = new EventEmitter();

  @Input() itemCode: string;
  @Input() externalSourceCode: string;

  @Input() showAuditButton: boolean = true;
  @Input() showCommentsButton: boolean = true;
  constructor() {}

  ngOnInit(): void {}

  onHide() {
    this.onCloseSidebar.emit(true);
  }
}
