import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeColor, Base, getModuleKeywordByCode, IAction, isNullObj, MessageKeys } from '@shared/classes';
import {
  AppDialogService,
  BaseFieldDefinitionsService,
  BaseRequestControllerService,
  PathResolverService,
  ToastService,
} from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { PrimeIcons } from 'primeng/api';
import { LanguagesPopupFormComponent } from '../languages-popup-form/languages-popup-form.component';

@Component({
  selector: 'app-languages-trail',
  templateUrl: './languages-trail.component.html',
  styleUrl: './languages-trail.component.scss',
})
export class LanguagesTrailComponent extends Base implements OnInit {
  events: any[];
  color = '#2ebae4';
  loading = true;
  colorMap = BadgeColor;
  icon = PrimeIcons.LINK;
  @Output() onAddItem: EventEmitter<any> = new EventEmitter();
  @Input()
  itemCode: string;
  @Input() autoFocus: boolean = false;
  addItemAction: IAction = {
    id: 1,
    label: 'Add Translation',
    buttonType: 'button',
    command: this.addTranslation.bind(this),
    icon: 'pi pi-plus',
  };
  editItemAction: IAction = {
    id: 2,
    label: '',
    buttonType: 'button',
    command: this.editTranslation.bind(this),
    icon: 'pi pi-pencil',
    buttonStyle: 'text',
    color: 'primary',
    tooltipText: 'Edit Translation',
  };
  swapItemAction: IAction = {
    id: 3,
    label: '',
    buttonType: 'button',
    command: this.swapLanguage.bind(this),
    icon: 'pi pi-sync',
    buttonStyle: 'text',
    color: 'secondary',
    tooltipText: 'Swap Main Translation',
  };
  moduleKeyword: any;
  dataService: BaseRequestControllerService<any>;
  mappingService: BaseFieldDefinitionsService;
  itemData = null;
  languageKeys: { key: string; value: any; valueFieldsCount: number }[] = [];
  constructor(
    private documentService: FilesDataService,
    private pathResolverService: PathResolverService,
    public appDialogService: AppDialogService,
    private toastr: ToastService
  ) {
    super();
  }

  ngOnInit() {
    this.moduleKeyword = getModuleKeywordByCode(this.itemCode);
    this.dataService = this.pathResolverService.getDataServiceByCode(this.itemCode);
    this.mappingService = this.pathResolverService.getMappingServiceByModuleKeyword(this.moduleKeyword);
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.dataService.getByIdOrCode(this.itemCode).subscribe({
      next: (res) => {
        this.itemData = res?.data;
        this.languageKeys = isNullObj(res?.data?.translations)
          ? []
          : Object.entries(res?.data?.translations).map(([key, value]) => {
              return { key: key, value: value, valueFieldsCount: value ? Object.keys(value).length : 0 };
            });
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  onDeleteItem(event, index) {
    this.events.splice(index, 1);
    this.events = [...this.events];
  }

  onSubmit(data) {
    let body = {
      comment: data?.comment,
      sourceCode: this.itemCode,
    };

    this.loading = true;
  }
  addTranslation() {
    this.appDialogService.showDialog(
      LanguagesPopupFormComponent,
      'Add Translation',
      (data) => {
        console.log(data);
        this.loadData();
      },
      {
        data: {
          dataService: this.dataService,
          mappingService: this.mappingService,
          originalData: this.itemData,
          itemCode: this.itemCode,
          formData: null,
        },
      }
    );
  }
  editTranslation(langKey) {
    this.appDialogService.showDialog(
      LanguagesPopupFormComponent,
      'Edit Translation',
      (data) => {
        console.log(data);
        this.loadData();
      },
      {
        data: {
          dataService: this.dataService,
          mappingService: this.mappingService,
          originalData: this.itemData,
          itemCode: this.itemCode,
          formData: { langCode: langKey, ...this.itemData?.translations?.[langKey] },
        },
      }
    );
    // this.dataService
    //   .addTranslation(this.itemCode, { langCode: 'en-US', translation: { name: 'englis hnameaisdduh' } })
    //   .subscribe((res) => {
    //     console.log(res);
    //   });
  }
  swapLanguage(langCode) {
    this.dataService.swapTranslation(this.itemCode, langCode).subscribe({
      next: (res) => {
        this.toastr.success(MessageKeys.success);
      },
    });
  }
}
