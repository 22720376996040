import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IAction } from '@shared/classes';
import { AppDialogService, ViewModeService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-page-popup',
  templateUrl: './custom-page-popup.component.html',
  styleUrl: './custom-page-popup.component.scss',
})
export class CustomPagePopupComponent extends BaseForm<any> implements OnInit, AfterViewInit {
  tagsStringControl = new FormControl(null);
  tabItems = [];
  searchItems = [];
  loading = false;
  buttonValid = new Subject<boolean>();

  saveButton: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    status$: this.buttonValid.asObservable(),
    command: this.onSubmit.bind(this),
    icon: 'pi pi-save',
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, null);
    if (!this.data) this.data = {};
    this.formData = this.data;
    config.width = '20rem';
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.buttonValid.next(false);

    this._setupSubscriptions();
  }
  getData() {
    return this.data;
  }
  setData(data: any) {}

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      page: new FormControl(null, Validators.required),
    });
    this.formGroup.controls.page.statusChanges.subscribe((x) => {
      this.buttonValid.next(x == 'VALID');
    });
  }

  onSubmit() {
    this.ref.close(this.formGroup?.controls?.page?.value ?? 1);
  }
}
